<template>
  <div class="card mb-5 mb-xl-12">
    <div class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
      
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">Uzman Danışman Tipleri</h3>

        
      </div>      
    </div>
    
    
    <div id="kt_account_profile_details" class="collapse show" v-if="items">

        <div id="package-detail" v-if="showDetail">
            <div class="card-body border-top p-9">                
                <EditConsultanForm :selectedItem="selectedItem" :mode="mode" v-if="selectedItem" />
            </div>

            <!--begin::Actions-->
            <div class="card-footer d-flex justify-content-end py-6 px-9">
                <button type="reset" @click.prevent="cancelForm()" class="btn btn-white btn-active-light-primary me-2">Vazgeç</button>

                <button type="submit" id="kt_account_profile_details_submit" ref="submitButton1" @click.prevent="update()" class="btn btn-primary">
                    <span class="indicator-label"> Kaydet </span>
                    <span class="indicator-progress">
                    Lütfen bekleyin...
                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                </button>
            </div>
            <!--end::Actions-->
        </div>


        <div class="card-body border-top p-9" v-show="!showDetail">
            <div class="table-responsive">
                <a href="" @click.prevent="add()" class="btn btn-primary float-right">Yeni Danışman Tipi Ekle</a>
              <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                <thead>
                    <tr class="fw-bolder">
                    <th>Danışman Tipi</th>
                    <th>Görüşme süresi</th>
                    <th>Görüşme ara süresi</th>
                    <th>Durum</th>                    
                    <th>İşlem</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in items" :key="i">
                    <td>{{item.consultantType}}</td>
                    <td>{{item.vcallDuration}}</td>
                    <td>{{item.restDuration}}</td>
                    <td><span :class="'badge badge-light-'+statusses.class[item.status]">{{statusses.label[item.status]}}</span></td>                    
                    <td><ActionsMenu :item="item" :packagekey="i" @edit="edit($event)" /></td>
                    </tr>            
                </tbody>
              </table>
      
            </div>
        </div>
    </div>
    
        
    
  </div>


</template>



<script>
import api from '@/core/services/ApiService'
import ActionsMenu from "@/components/dropdown/ConsultantTypesListActions.vue";
import EditConsultanForm from '@/components/modals/forms/EditConsultantType.vue';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";

const newItemValues={
    consultantType:null,
    vcallDuration:"",
    status:'AC',
    restDuration:"",
}

export default  {
  name:'PackageGroups',
  components:{
      ActionsMenu,
      EditConsultanForm,      
  },
  data(){
    
    return{        
        selectedItem:newItemValues,
        mode:'new',

      showDetail:false,
      
      statusses : {
        class:{
          'AC':'success',
          'DA':'danger'
        },
        label:{
          'AC':'Aktif',
          'DA':'Pasif'
        }
      },       
      items:null,
      

    }
  },

  

  methods:{
      edit(item){
          this.showDetail=true;
          this.mode='edit';
          this.selectedItem = item;
      },

      cancelForm(){
          this.showDetail=false;
      },

      add(){
          this.mode='new';
          this.selectedItem = newItemValues;        
          this.showDetail = true;

      },

      

      update(){
          let apiAddress = this.mode=='new' ? '/User/NewDoctorType' : '/User/UpdateDoctorType';

          api.post(apiAddress,this.selectedItem).then((res)=>{
              Swal.fire({
                  text:'Danışman tipi güncellendi',
                  icon:'success',
              }).then(()=>{
                  window.location.reload();
              });
          }).catch((err)=>{
              console.log(err);
          })
      }
  },


  created(){
     setCurrentPageBreadcrumbs("Uzman Danışman Tipleri", ["Ayarlar","Tanımlamalar"]);
     api.get('/User/ListDoctorType/JJ').then((res)=>{
         this.items = res.data;
     });
   
  },

}

</script>
<style scoped>
input:disabled{cursor: not-allowed; background-color: #eee;}
</style>