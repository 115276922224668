<template>
    <div>
        
        <div class="row">
            <div class="col-lg-9">
                <div class="row mb-10">
                    <div class="col-lg-12 fv-row">
                        <label class="col-form-label">Doktor Tipi</label>                            
                        <input type="text" name="name" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" v-model="selectedItem.consultantType" />
                    </div>
                </div>

                <div class="row mb-10">
                    <div class="col-lg-12 fv-row">
                        <label class="col-form-label">Görüşme Süresi</label>                            
                        <select class="form-control form-select" name="vcallDuration" v-model="selectedItem.vcallDuration" id="vcallDuration" :disabled="mode=='edit'">
                            <option value="">Seçiniz</option>
                            <option :value="i*10" :key="i" v-for="i in 5">{{i*10}} Dakika</option>
                        </select>
                    </div>
                </div>

                <div class="row mb-10">
                    <div class="col-lg-12 fv-row">
                        <label class="col-form-label">Görüşme Ara Süresi</label>                            
                        <select class="form-control form-select" name="restDuration" v-model="selectedItem.restDuration" id="restDuration" :disabled="mode=='edit'">
                            <option value="">Seçiniz</option>
                            <option :value="i*10" :key="i" v-for="i in 5">{{i*10}} Dakika</option>
                        </select>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 fv-row">
                        <label class="col-form-label">Durum</label> 
                            <div class="d-flex align-items-center mt-3">
                            <label class="form-check form-check-inline form-check-solid me-5">
                                <input class="form-check-input" value="AC" name="status" :checked="selectedItem.status == 'AC'" type="radio" v-model="selectedItem.status" />
                                <span class="fw-bold ps-2 fs-6"> Aktif </span>
                            </label>

                            <label class="form-check form-check-inline form-check-solid">
                                <input class="form-check-input"  value="DA" name="status" :checked="selectedItem.status == 'DA'" type="radio" v-model="selectedItem.status" />
                                <span class="fw-bold ps-2 fs-6"> Pasif </span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

export default {
    name:'EditPackage',
    props:['selectedItem','mode','packageGroupId'],
   
        
}
</script>